import { useRouter } from 'next/router';

import { GIFT_URL } from '../../globals';
import { useMobileDetector } from '../../hooks';
import { BrandLogo } from '../_core/BrandLogo';
import MaxWidth from '../shared/MaxWidth';
import { NavigationLink } from '../shared/NavigationLink';
import { ColumnWithLinks } from './ColumnWithLinks';
import { CopyrightText } from './CopyrightText';
import { DownloadLinks } from './DownloadLinks';
import { MediaLinks } from './MediaLinks';
import * as S from './styles';

const Footer: React.FC = () => {
  const { isMobile } = useMobileDetector();

  const router = useRouter();
  const isDownloadPage = router.pathname === '/download';

  return (
    <S.FooterContainer
      style={{
        paddingBottom: isMobile ? '8rem' : 0,
        backgroundColor: isDownloadPage ? 'black' : 'unset',
      }}
    >
      <MaxWidth style={{ maxWidth: isDownloadPage ? '1220px' : '1328px' }}>
        <S.ContentWrapper>
          <S.FirstColumn>
            <BrandLogo analytics={{ category: 'Footer', action: 'Brand Footer Click' }} />
            <CopyrightText />
            <MediaLinks />
            <div />
          </S.FirstColumn>

          <S.Row>
            <S.Column>
              <ColumnWithLinks title="Learn More">
                <NavigationLink
                  action="About Page Link Click"
                  link="/about"
                  text="About us"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Science Page Link Click"
                  link="/science"
                  text="Our Science"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Download Page Link Click"
                  link="/download"
                  text="Download"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Press Page Link Click"
                  link="/press"
                  text="Press"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Pricing Page Link Click"
                  link="/pricing"
                  text="Pricing"
                  type={'footerLink'}
                />
                {/*<NavigationLink type={"footerLink"} text="Learn" link="/learn" action="Learn Page Link Click" />*/}
                <NavigationLink
                  action="Work With Us Page Link Click"
                  external
                  link="/jobs"
                  text="Work With Us"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Gift Page Link Click"
                  external
                  link={GIFT_URL}
                  text="Gift"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="ADHD Page Link Click"
                  link="/adhd"
                  text="ADHD"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Procrastination Page Link Click"
                  link="/procrastination"
                  text="Procrastination"
                  type={'footerLink'}
                />
                <br />
              </ColumnWithLinks>
            </S.Column>

            <S.Column>
              <ColumnWithLinks title="More Resources">
                {/* <NavigationLink type={"footerLink"} action="FAQ Page Link Click" link="/faq" text="Knowledge Base" /> */}
                <NavigationLink
                  action="Contact Page Link Click"
                  link="/contact"
                  text="Contact Us"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Terms Page Link Click"
                  link="/terms"
                  text="Terms of Use"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Privacy Page Link Click"
                  link="/privacy"
                  text="Privacy"
                  type={'footerLink'}
                />
                <NavigationLink
                  action="Legal Page Link Click"
                  link="/legal"
                  text="Legal"
                  type={'footerLink'}
                />
                <br />
              </ColumnWithLinks>
            </S.Column>
          </S.Row>

          <S.Column>
            <ColumnWithLinks title="Listen now">
              <DownloadLinks />
            </ColumnWithLinks>
          </S.Column>
        </S.ContentWrapper>
      </MaxWidth>
    </S.FooterContainer>
  );
};

export default Footer;
